import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CODES } from "../../../../utils/codesHTTP";
import { setOpenLoader, } from '../../../../config/redux/actions';
import Condition from './Condition';
import DynamicTitle from "../../../../utils/DynamicTitle";
import GeneralService from "../../../../services/general";
import PlantService from '../../../../services/plant';
import TypeConditionService from '../../../../services/typeCondition';
import CategoryTypeConditionService from '../../../../services/categoryTypeCondition';
import { titlesSpinner } from '../../../../message/titlesSpinner';
import { setTitleLoader } from '../../../../config/redux/actions';
import ConditionCategory from './ConditionCategory';
import ConditionsAssignment from './ConditionsAssignment';

const UnsafeConditionConcepts = () => {
  const dispatch = useDispatch();
  const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
  const handleTitleLoader = (value) => dispatch(setTitleLoader(value));
  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (index) => setActiveTab(index);

  const [dataConditions, setDataConditions] = useState(null);
  const [dataConditionsCategory, setDataConditionsCategory] = useState(null);
  const [valueCondition, setValueCondition] = useState(null);

  const [dataPlants, setDataPlants] = useState(null);
  const [dataConditionsCopy, setDataConditionsCopy] = useState(null);
  const [states, setStates] = useState(null);

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    handleOpenLoader(true);
    Promise.all([
      getCondition(),
      getStates(),
      getCategoryByTypeCondition(),
      getPlants()
    ]).then(() => handleOpenLoader(false));
  }

  useEffect(() => {
    if (valueCondition !== null && valueCondition > 0) {
      getCategoryByTypeCondition();
    }
  }, [valueCondition])

  const getStates = async () => {
    try {
      setStates(null);
      const result = await GeneralService.showStates();
      if (result.status === CODES.SUCCESS_200) {
        setStates(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en Plant showAll: ${error}`);
    }
  }

  const getPlants = async () => {
    try {
      setDataPlants(null);
      const result = await PlantService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setDataPlants(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en Plant showAll: ${error}`);
    }
  }

  const getCondition = async () => {
    try {
      setDataConditions([]);
      const result = await TypeConditionService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setDataConditions(result.data);
        setDataConditionsCopy([...result.data]);
      }
      return []
    } catch (error) {
      console.log(`Error en getCondition showAll: ${error}`);
    }
  }

  const getCategoryByTypeCondition = async () => {
    try {
      if (valueCondition === null || valueCondition <= 0) {
        return;
      }

      setDataConditionsCategory(null);
      handleOpenLoader(true);
      handleTitleLoader(titlesSpinner.categoyCondition_list);
      const result = await CategoryTypeConditionService.showCategoryByTypeCondition(valueCondition);
      if (result.status === CODES.SUCCESS_200) {
        setDataConditionsCategory(result.data);
      }
      return [];
    } catch (error) {
      console.log(`Error en getCategoryByTypeCondition: ${error}`);
    } finally {
      handleOpenLoader(false);
      handleTitleLoader(null);
    }
  };

  return (
    <>
      <DynamicTitle title="Definición Condicion insegura" />
      <ul className="nav nav-underline justify-content-center">
        <li className="nav-item" onClick={() => handleChangeTab(0)}>
          <a className={`nav-link ${activeTab === 0 ? 'active' : ''}`}>Condición</a>
        </li>
        <li className="nav-item" onClick={() => handleChangeTab(1)}>
          <a className={`nav-link ${activeTab === 1 ? 'active' : ''}`}>Categoria</a>
        </li>
        <li className="nav-item" onClick={() => handleChangeTab(2)}>
         {// <a className={`nav-link ${activeTab === 2 ? 'active' : ''}`}>Asignaciones</a>
         }
        </li>
      </ul>
      <div className="content-container">
        <div className={`nav-content ${activeTab === 0 ? 'nav-slide-in' : ''}`}>
          <Condition
            states={states}
            data={dataConditions}
            setData={setDataConditions}
            getInfo={getCondition}
          />
        </div>
        <div className={`nav-content ${activeTab === 1 ? 'nav-slide-in' : ''}`}>
          <ConditionCategory
            states={states}
            data={dataConditionsCategory}
            setData={setDataConditionsCategory}
            getInfo={getCategoryByTypeCondition}
            dataConditions={dataConditions}
            valueCondition={valueCondition}
            setValueCondition={setValueCondition}
          />
        </div>
        <div className={`nav-content ${activeTab === 2 ? 'nav-slide-in' : ''}`}>
          <ConditionsAssignment
            states={states}
            data={dataConditionsCopy}
            setData={setDataConditionsCopy}
            getInfo={getCondition}
            dataPlants={dataPlants}
          />
        </div>
      </div>
    </>
  )
}

export default UnsafeConditionConcepts