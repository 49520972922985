import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = 'report';
const url = window.location.pathname;
const commonParams = { url: url };

class ReportService {

    generateReportDTO = async (informationInspection, rucEmpresa) => {
        const ruta = `${API_URL}/export/DTO`;
        const params = { ...commonParams };

        // Empaquetar ambos datos en un solo objeto
        const data = {
            ...informationInspection,
            rucEmpresa: rucEmpresa
        };

        try {
            const result = await axios.post(ruta, data, {
                headers: headerRequest(),
                params: params,
                responseType: 'blob'
            });
            return result;
        } catch (error) {
            return error;
        }
    };

    generateReportSST = async (informationInspection, rucEmpresa) => {
        const ruta = `${API_URL}/export/SST`;
        const params = { ...commonParams };
        // Empaquetar ambos datos en un solo objeto
        const data = {
            ...informationInspection,
            rucEmpresa: rucEmpresa
        };

        try {
            const result = await axios.post(ruta, data, {
                headers: headerRequest(),
                params: params,
                responseType: 'blob'
            });
            return result;
        } catch (error) {
            return error;
        }
    }

    generateReport5S = async (informationInspection, rucEmpresa) => {
        const ruta = `${API_URL}/export/5S`;
        const params = { ...commonParams };
        // Empaquetar ambos datos en un solo objeto
        const data = {
            ...informationInspection,
            rucEmpresa: rucEmpresa
        };

        try {
            const result = await axios.post(ruta, data, {
                headers: headerRequest(),
                params: params,
                responseType: 'blob'
            });
            return result;
        } catch (error) {
            return error;
        }
    }

    generateReport = async (path, body) => {
        const ruta = `${API_URL}/${prefix}/${path}`;
        const params = { ...commonParams };

        try {
            const result = await axios.post(ruta, body, {
                headers: headerRequest(),
                params: params,
                responseType: 'blob'
            });
            return result;
        } catch (error) {
            return error;
        }
    }
}

export default new ReportService();
