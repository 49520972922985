import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GeneralService from '../../../services/general';
import TypeEventService from '../../../services/typeEvent';
import AccidentService from '../../../services/accident';
import CustomSelect from "../../components/General/CustomSelect";
import IncidentLocationFields from '../../components/General/IncidentLocationFields';
import CustomTextArea from '../../components/General/CustomTextArea';
import AddWorker from '../../components/Workers/AddWorker/AddWorker';
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../message/validations';
import { getDateOL } from '../../../utils/function';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { titlesAlerts } from '../../../message/titlesAlerts';
import { errorsMessages } from '../../../message/errors';
import { CODES } from '../../../utils/codesHTTP';
import DynamicTitle from '../../../utils/DynamicTitle';
import _ from 'lodash';
import Swal from 'sweetalert2';
import Involved from '../../components/Accident/Involved/Involved';

const newInfo = {
    fecha: getDateOL("", "large"),
    desSuceso: null,
    desAccion: null,
    subAreaLugar_id: null,
    tipoEvento_id: null,
    turno_id: null,
    planta_id: null,
    area_id: null,
    subArea_id: null,
    lugar_id: null,
    afectados: [],
    testigos: [],
    supervisores: [],
    imagenes: [],
    imagenesCierre: [],
}

const Accident = () => {
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const places = useSelector(state => state.places);
    const valuePlant = useSelector(state => state.valuePlant);

    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState(newInfo);
    const [valueTypeWorker, setValueTypeWorker] = useState(null);
    const [valueTypeInvolved, setValueTypeInvolved] = useState(1);
    const [dataTypeWorker, setDataTypeWorker] = useState(null);
    const [dataShift, setDataShift] = useState(null);
    const [dataTypeEvent, setDataTypeEvent] = useState(null);
    const [dataTypeInvolved, setDataTypeInvolved] = useState(null);
    const [workersSelected, setWorkersSelected] = useState(null);

    const [turno, setTurno] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        handleOpenLoader(true);
        Promise.all([
            showTypeWorker(),
            showTypeInvolved(), showTurnos(),
        ]).then(() => handleOpenLoader(false));
    }

    const showTypeWorker = async () => {
        try {
            setDataTypeWorker(null);
            const result = await GeneralService.showTypeWorker();
            setDataTypeWorker(result.data);
            return [];
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    const showTypeInvolved = async () => {
        try {
            setDataTypeInvolved(null);
            const result = await GeneralService.showTypeInvolved();
            setDataTypeInvolved(result.data);
            return [];
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    useEffect(() => {
        if (valuePlant > 0) {
            showShiftByPlant();
        }
    }, [valuePlant]);

    const showShiftByPlant = async () => {
        try {
            setDataShift(null);
            const result = await GeneralService.showShiftByPlant(valuePlant);
            setDataShift(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        }
    }

    useEffect(() => {
        setDataTypeEvent(null);
        if (info?.area_id > 0)
            showTypeEventByArea();
    }, [info?.area_id]);

    const showTypeEventByArea = async () => {
        try {
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.categoyCondition_list);
            setDataTypeEvent(null);
            const result = await TypeEventService.showTypeEventByArea(info?.area_id);
            setDataTypeEvent(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showCategoryByTypeCondition: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const allowedFileTypes = ['.jpg', '.jpeg', '.png'];
    const handleFilesChange = (e, field) => {
        const files = e.target.files;
        let updData = _.cloneDeep(info);
        if (updData[field]) {
            updData[field] = [];
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileExtension = file?.name?.split('.').pop().toLowerCase();
            if (!allowedFileTypes.includes(`.${fileExtension}`)) {
                simpleAlerts({ message: errorsMessages.type_image })
            } else {
                let small;
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        small = { file: file, img: event.target.result, name: file?.name };
                        updData[field].push({ file: file, small: small });
                        setInfo(updData);
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    const handleRemoveFile = (field, idx) => {
        let updData = _.cloneDeep(info);
        updData[field] = updData[field]?.filter((e, i) => +i !== idx);
        return setInfo(updData);
    };

    const viewFile = (info) => {
        const fileURL = URL.createObjectURL(info?.small?.file);
        if (info?.small?.file) {
            window.open(fileURL, '_blank');
        }
    };

    const resetInfo = () => {
        setInfo(newInfo);
        setValueTypeWorker(null);
        setValueTypeInvolved(1);
        setWorkersSelected(null);
    }

    const handleOpenModal = () => {
        if (!valuePlant || valuePlant === 0) {
            simpleAlerts({ message: validationMessages.planta })
            return;
        }
        if (!valueTypeWorker || valueTypeWorker < 0) {
            simpleAlerts({ message: validationMessages.tipoTrabajador })
            return;
        }
        if (!valueTypeInvolved || valueTypeInvolved < 0) {
            simpleAlerts({ message: validationMessages.tipoTrabajador })
            return;
        }
        setOpen(true);
    }

    const saveUnsafeCondition = async (e) => {
        e.preventDefault();

        let updData = _.cloneDeep(info);
        updData.trabajadores = [...updData?.afectados, ...updData?.testigos, ...updData?.supervisores]

        if (!updData.fecha || updData.fecha === "") {
            simpleAlerts({ message: validationMessages.date })
            return;
        }
        if (!updData.lugar_id || updData.lugar_id === 0) {
            simpleAlerts({ message: validationMessages.lugar })
            return;
        }
        if (!updData.turno_id || updData.turno_id === 0) {
            simpleAlerts({ message: validationMessages.turno })
            return;
        }
        if (!updData.tipoEvento_id || updData.tipoEvento_id === 0) {
            simpleAlerts({ message: validationMessages.tipoEvento })
            return;
        }
        if (!updData.desSuceso || updData.desSuceso === "") {
            simpleAlerts({ message: validationMessages.desSuceso })
            return;
        }
        if (!updData.desAccion || updData.desAccion === "") {
            simpleAlerts({ message: validationMessages.desAccion })
            return;
        }
        if (!updData.afectados || updData.afectados?.length < 1) {
            simpleAlerts({ message: validationMessages.afectados })
            return;
        }
        if (!updData.testigos || updData.testigos?.length < 1) {
            simpleAlerts({ message: validationMessages.testigos })
            return;
        }
        if (!updData.supervisores || updData.supervisores?.length < 1) {
            simpleAlerts({ message: validationMessages.supervisores })
            return;
        }

        updData.planta_id = valuePlant;
        updData.subAreaLugar_id = places?.find(e =>
        (+e.planta_id === +updData.planta_id &&
            +e.area_id === +updData.area_id &&
            +e.subArea_id === +updData.subArea_id &&
            +e.id_lugar === +updData.lugar_id)
        )?.id_subAreaLugar

        const resultSwal = await Swal.fire({
            title: titlesAlerts.confirm_accident,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(titlesSpinner.accident_save);
                const result = await AccidentService.saveAccident(updData);
                if (result.status === CODES.CREATE_201) {
                    resetInfo();
                    simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
                } else {
                    console.log(result?.response);
                    simpleAlerts({ message: errorsMessages.unsafe_behavior, type: "error" })
                }
                return []
            } catch (error) {
                console.log(`Error en saveUnsafeCondition: ${error}`);
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    useEffect(() => {
        // if (workersSelected && workersSelected?.length > 0) {
        //     if (valueTypeInvolved === 1) {
        //         setInfo({ ...info, afectados: workersSelected });
        //     }
        //     if (valueTypeInvolved === 2) {
        //         setInfo({ ...info, testigos: workersSelected });
        //     }
        //     if (valueTypeInvolved === 3) {
        //         setInfo({ ...info, supervisores: workersSelected });
        //     }
        // }
        if (workersSelected && workersSelected.length > 0) {
            setInfo(prevInfo => {
                let key = "";
                if (valueTypeInvolved === 1) key = "afectados";
                if (valueTypeInvolved === 2) key = "testigos";
                if (valueTypeInvolved === 3) key = "supervisores";

                if (!key) return prevInfo; // Si valueTypeInvolved no coincide, no hacer nada

                return {
                    ...prevInfo,
                    [key]: [
                        ...(prevInfo[key] || []),
                        ...workersSelected.filter(worker =>
                            !(prevInfo[key] || []).some(t => t.numdoc === worker.numdoc)
                        )
                    ]
                };
            });
        }
    }, [workersSelected]);


    const showTurnos = async () => {
        try {
            setDataTypeWorker(null);
            const result = await GeneralService.showTurnos();
            setTurno(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showTurnos: ${error}`);
        }
    }

    return (
        <form className="px-3"
            onSubmit={saveUnsafeCondition}
        >
            <AddWorker
                open={open}
                setOpen={setOpen}
                pageRequest={"ACCIDENT"}
                valueTypeWorker={valueTypeWorker}
                valueTypeInvolved={valueTypeInvolved}
                workersSelected={workersSelected}
                setWorkersSelected={setWorkersSelected}
            />
            <DynamicTitle title="Accidente - Incidente" />
            <h1 className='text-center title-page'>Accidente e Incidente</h1>
            <section className='form-register'>
                <IncidentLocationFields
                    info={info}
                    setInfo={setInfo}
                />
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomSelect
                            title={'Turno'}
                            placeholder={'Selecionar Turno'}
                            options={turno}
                            value={info.turno_id || 0}
                            onChange={(value, event) => setInfo({ ...info, turno_id: value })}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <CustomSelect
                            title={'Tipo de Evento:'}
                            placeholder={'Selecionar Tipo'}
                            options={dataTypeEvent}
                            value={info.tipoEvento_id || 0}
                            onChange={(value, event) => setInfo({ ...info, tipoEvento_id: value })}
                        />
                    </div>
                </div>
                {/* imagenes */}
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <div className='text-center'>
                            <input
                                id={`fileInput-form-register`}
                                type="file"
                                accept={allowedFileTypes}
                                onChange={(e) => handleFilesChange(e, 'imagenes')}
                                style={{ display: 'none' }}
                                multiple
                            />
                            <button
                                className='btn btn-sm btn-outline-primary ms-2'
                                type='button'
                                onClick={() => document.getElementById(`fileInput-form-register`).click()}
                                title='Subir imagen'
                            >
                                <CloudUploadIcon color="inherit" fontSize='small' />
                            </button>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <div className='table-container-wrapper mt-2'>
                            <div className='table-container'>
                                {info?.imagenes?.length > 0 ?
                                    (info?.imagenes?.map((row, idxRow) => {
                                        return (
                                            <div key={`row-image-${idxRow}`} className='row-worker-container'>
                                                <div className='col-worker-container'>
                                                    <button
                                                        className='btn btn-sm btn-outline-danger ms-2 p-0 border-0'
                                                        type='button'
                                                        onClick={() => handleRemoveFile("imagenes", idxRow)}
                                                        title='Quitar imagen'
                                                    >
                                                        <CancelIcon fontSize='small' />
                                                    </button>
                                                </div>
                                                <div className='col-worker-container' style={{ width: "93%" }}>
                                                    <img
                                                        src={row.small.img}
                                                        alt="Debe seleccionar una imagen correcta"
                                                        style={{ width: '30px', cursor: 'pointer' }}
                                                        className='ms-3'
                                                        onClick={() => viewFile(row)}
                                                    />
                                                    <label className='ms-2'>{row.small.name}</label>
                                                </div>
                                            </div>
                                        );
                                    }))
                                    : <div className='row-worker-container'>
                                        <div className='col-worker-container' style={{ width: '100%' }}>
                                            <label className='mx-2'>Ingresar imagenes</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* trabajadores */}
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-8 col-sm-8 d-flex justify-content-evenly align-items-end'>
                        <div style={{ width: '100%', }}>
                            <CustomSelect
                                title={'Tipo de Trabajador'}
                                placeholder={'Selecionar Tipo'}
                                options={dataTypeWorker}
                                value={valueTypeWorker || 0}
                                onChange={(value, event) => setValueTypeWorker(value)}
                            />
                        </div>
                        <button
                            className='btn btn-sm btn-outline-primary ms-2'
                            type='button'
                            onClick={handleOpenModal}
                            title='Buscar Trabajadores'
                        >
                            <SearchIcon fontSize='small' />
                        </button>
                    </div>
                    <div className='col-md-8 col-sm-8'>
                        <Involved
                            titles={dataTypeInvolved}
                            involved={valueTypeInvolved}
                            setInvolved={setValueTypeInvolved}
                            info={info}
                            setInfo={setInfo}
                            setWorkersSelected={setWorkersSelected}
                        />
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomTextArea
                            title="Descripción:"
                            placeholder="ingrese una descripción 500 max."
                            propsInput={{ maxLength: 500, }}
                            name="desSuceso"
                            value={info?.desSuceso || ""}
                            onChange={(e) => setInfo({ ...info, desSuceso: e.target.value })}
                            required={true}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <CustomTextArea
                            title="Acciones:"
                            placeholder="ingrese una descripción 500 max."
                            propsInput={{ maxLength: 500, }}
                            name="desAccion"
                            value={info?.desAccion || ""}
                            onChange={(e) => setInfo({ ...info, desAccion: e.target.value })}
                            required={true}
                        />
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <button
                            className='btn btn-block btn-danger w-100'
                            // onClick={addPlace}
                            title='Cancelar'
                        >
                            Cancelar
                        </button>
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <button
                            className='btn btn-block btn-primary w-100'
                            // onClick={addPlace}
                            title='Guardar'
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default Accident;