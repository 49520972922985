import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenLoader,
  setTitleLoader,
} from "../../../../config/redux/actions";
import WorkerService from "../../../../services/worker";
import CustomInputSearch from "../../General/CustomInputSearch";
import CustomModal from "../../../components/General/CustomModal";
import InformationWorker from "../InformationWorker/InformationWorker";
import { titlesModal } from "../../../../message/titlesModal";
import { CODES } from "../../../../utils/codesHTTP";
import { simpleAlerts } from "../../../../utils/alerts";
import { titlesAlerts } from "../../../../message/titlesAlerts";
import SearchIcon from "@mui/icons-material/Search";

const AddWorker = (props) => {
  const {
    open,
    setOpen,
    pageRequest,
    typeSelection = "multiple",
    valuePlantAux = null,
    valueTypeWorker,
    valueTypeInvolved,
    workersSelected,
    setWorkersSelected,data, setData
  } = props;

  const dispatch = useDispatch();
  const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
  const handleTitleLoader = (value) => dispatch(setTitleLoader(value));
  const valuePlant = useSelector((state) => state.valuePlant);
  const [dataWorkers, setDataWorkers] = useState(null);
  const [dataWorkers2, setDataWorkers2] = useState(null); // solo para consulta dni
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [dni, setDni] = useState("");
  const [nombres, setNombres] = useState("");
  const [apePaterno, setApePaterno] = useState("");
  const [apeMaterno, setApeMaterno] = useState("");

  useEffect(() => {
    if (dataWorkers2) {
      setApePaterno((prev) => dataWorkers2.apePaterno || prev);
      setApeMaterno((prev) => dataWorkers2.apeMaterno || prev);
      setNombres((prev) => dataWorkers2.nombres || prev);
    }
  }, [dataWorkers2]);

  useEffect(() => {
    if (open && +valueTypeWorker === 1) {
      handleSearchWorker();
    }
  }, [open, valuePlant, valueTypeWorker, page]);

  useEffect(() => {
    setDataWorkers(null);
  }, [search]);

  useEffect(() => {
    if (open && +valueTypeWorker === 2) {
        showOthersByName();
    }
  }, [open,valueTypeWorker]);
 

  const handleClose = () => {
    setOpen(false);
    setSearch("");
    setDataWorkers(null);
    //setWorkersSelected(null);
    setDni(null);
    setNombres(null);
    setApePaterno(null);
    setApeMaterno(null);
    setDataWorkers2(null);
    setTotalPage(null);
    
  };

  const handleSearchWorker = () => {
    if (valuePlant > 0 || valuePlantAux > 0) {
      if (+valueTypeWorker === 1) {
        getWorkerPlant();
      } else {
       
        showOthersByName();
      }
    }
  };

  const getWorkerPlant = async () => {
    try {
      handleOpenLoader(true);
      handleTitleLoader("Buscando trabajadores...");
      setDataWorkers(null);
      const params = { search, page, id_planta: valuePlant || valuePlantAux };
      const result = await WorkerService.showByPlant(params);
      if (result.status === CODES.SUCCESS_200) {
        setDataWorkers(result?.data?.data);
        setTotalPage(result?.data?.last_page);
      }
    } catch (error) {
      console.error(`Error en showCompanies: ${error}`);
    } finally {
      handleOpenLoader(false);
      handleTitleLoader(null);
    }
  };

  const getWorkerOthers = async () => {
    try {
      handleOpenLoader(true);
      handleTitleLoader("Buscando trabajador...");
      setDataWorkers2(null);
      const params = { numdoc: dni };
      const result = await WorkerService.showOthers(params);
      if (result.status === CODES.SUCCESS_200) {
        setDataWorkers2(result?.data);
      } else {
        simpleAlerts({
          message:
            result?.response?.data?.message || "Trabajador no encontrado",
        });
      }
    } catch (error) {
      console.error(`Error en showCompanies: ${error}`);
    } finally {
      handleOpenLoader(false);
      handleTitleLoader(null);
    }
  };

  const showOthersByName = async () => {
    try {
      handleOpenLoader(true);
      handleTitleLoader("Buscando trabajador...");
      setDataWorkers(null);
      const params = { search: search };
      const result = await WorkerService.showOthersByName(params);
      if (result.status === CODES.SUCCESS_200) {
        setDataWorkers(result?.data);
      } else {
        simpleAlerts({
          message:
            result?.response?.data?.message || "Trabajador no encontrado",
        });
      }
    } catch (error) {
      console.error(`Error en showCompanies: ${error}`);
    } finally {
      handleOpenLoader(false);
      handleTitleLoader(null);
    }
  };

  const handleSave = async () => {
    try {
      handleOpenLoader(true);
      handleTitleLoader("Registrando persona...");

      const body = {
        numdoc: dni,
        nombres: nombres,
        apePaterno: apePaterno,
        apeMaterno: apeMaterno,
      };

      const result = await WorkerService.registerOrCheckPerson(body);

      if (result.status === CODES.SUCCESS_200) {
        simpleAlerts({
          message: "Persona registrada exitosamente",
          type: "success",
        });
        setSearch("");
        setDni("");
        setNombres("");
        setApePaterno("");
        setApeMaterno("");
      } else {
        simpleAlerts({
          message:
            result?.response?.data?.message || "Error al registrar persona",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error en registerOrCheckPerson:", error);
      simpleAlerts({
        message: "Hubo un error al procesar la solicitud",
        type: "error",
      });
    } finally {
      showOthersByName();
      handleOpenLoader(false);
      handleTitleLoader(null);
    }
  };

  return (
    <CustomModal
      size={"sm"}
      open={open}
      handleClose={handleClose}
      title={titlesModal.listWorker}
      subTitle={titlesModal.listWorker_sub}
      bodyModal={
        <div className="px-1">
          <div className="row">
            {+valueTypeWorker === 2 ? (
              <>
                <div className="col-12">
                  <div className="d-flex align-items-center gap-1 mb-2">
                    <input
                      type="text"
                      placeholder="DNI"
                      value={dni}
                      onChange={(e) => {
                        if (e.target.value.length <= 8) {
                          setDni(e.target.value);
                        }
                      }}
                      className="form-control"
                      style={{ maxWidth: "120px" }}
                      maxLength={8} // Refuerza el límite en el input
                    />
                    <button
                      className="btn btn-sm btn-outline-primary"
                      type="button"
                      title="Buscar DNI"
                      onClick={getWorkerOthers}
                    >
                      <SearchIcon fontSize="small" />
                    </button>
                    <input
                      type="text"
                      placeholder="Nombres"
                      value={nombres}
                      onChange={(e) => setNombres(e.target.value)}
                      className="form-control flex-grow-1"
                    />
                  </div>
                  <div className="d-flex gap-2">
                    <input
                      type="text"
                      placeholder="Apellido Paterno"
                      value={apePaterno}
                      onChange={(e) => setApePaterno(e.target.value)}
                      className="form-control"
                    />
                    <input
                      type="text"
                      placeholder="Apellido Materno"
                      value={apeMaterno}
                      onChange={(e) => setApeMaterno(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="col-12">
            <CustomInputSearch
              placeholder="Buscar trabajador"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClick={handleSearchWorker}
            />
          </div>
          <InformationWorker
            typeSelection={typeSelection}
            dataWorkers={dataWorkers}
            page={page}
            setPage={setPage}
            totalPage={totalPage}
            valueTypeWorker={valueTypeWorker}
            valueTypeInvolved={valueTypeInvolved}
            workersSelected={workersSelected}
            setWorkersSelected={setWorkersSelected}
            data={data} setData={setData}
          />
        </div>
      }
      buttonActions={
        +valueTypeWorker === 2
          ? [
              <button
                className="btn btn-sm btn-outline-primary ms-2"
                type="button"
                onClick={handleSave}
                title="Procesar"
              >
                Procesar
              </button>,
            ]
          : null
      }
    />
  );
};

export default AddWorker;
