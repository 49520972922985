import React, { useState } from 'react'
import { useDispatch, } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import ReportService from '../../../services/report';
import { format, addDay, diffDays } from "@formkit/tempo"
import DynamicTitle from '../../../utils/DynamicTitle';
import CustomInput from '../../components/General/CustomInput';
import sheetImg from '../../../assets/icons/sheet.png'
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../message/validations';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { errorsMessages } from '../../../message/errors';
import { CODES } from '../../../utils/codesHTTP';
import { titlesAlerts } from '../../../message/titlesAlerts';

const reporButtons = [
    { id: "unsafe-behavior", title: "Comportamiento Inseguro" },
    { id: "unsafe-condition", title: "Condición Insegura" },
    { id: "accident-incident", title: "Accidentes / Incidentes" },
];

const Report = () => {
    const dateLanguage = 'es';
    const day_limit = 30;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [fecIni, setFecIni] = useState(format(addDay(new Date(), -day_limit), "YYYY-MM-DD", dateLanguage));
    const [fecFin, setFecFin] = useState(format(new Date(), "YYYY-MM-DD", dateLanguage));

    const generateReport = async (id, title) => {
        if (diffDays(fecFin, fecIni) > day_limit) {
            simpleAlerts({ message: validationMessages.limit_days_report })
            return;
        }
        if (!fecIni) {
            simpleAlerts({ message: validationMessages.dateInit })
            return;
        }
        if (!fecFin) {
            simpleAlerts({ message: validationMessages.dateEnd })
            return;
        }

        try {
            const body = {
                fecIni,
                fecFin,
            }
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.report_generate);

            const result = await ReportService.generateReport(id, body);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${title}.xlsx`;
                link.click();
                URL.revokeObjectURL(url);

                simpleAlerts({ message: titlesAlerts.report, type: "success" })
            } else {
                simpleAlerts({ message: errorsMessages.report_information, type: "error" })
            }
            return []
        } catch (error) {
            console.log(`Error en exportReporteAnalista: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    };

    return (
        <div className="container">
            <DynamicTitle title="Reportes SST" />
            <h1 className="text-center title-page">Reportes SST</h1>
            <div className='row justify-content-center gap-2 align-items-end'>
                <div className='col-md-4 col-sm-8'>
                    <CustomInput
                        title={"Desde"}
                        type={"date"}
                        value={fecIni}
                        name={"fecha"}
                        onChange={(e) => setFecIni(e.target.value)}
                        required={true}
                    />
                </div>
                <div className='col-md-4 col-sm-8'>
                    <CustomInput
                        title={"Hasta"}
                        type={"date"}
                        value={fecFin}
                        name={"fecha"}
                        onChange={(e) => setFecFin(e.target.value)}
                        required={true}
                    />
                </div>
            </div>
            <div className="row justify-content-center gap-1 mt-5 align-items-end">
                {reporButtons.map((btn) => (
                    <div key={btn.id} className="col-6 col-sm-4 col-md-3 d-flex justify-content-center">
                        <button
                            className="btn btn-sm btn-outline-success"
                            type="button"
                            onClick={() => generateReport(btn.id, btn.title)}
                            title={btn.title}
                            style={{ width: "150px", height: "120px", whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                            <img src={sheetImg} width={50} />
                            <span className="mt-1 text-wrap d-block">{btn.title}</span>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Report