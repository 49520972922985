import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../assets/styles/navBar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenLoader, setTitleLoader, setSelectedInfo } from '../../../config/redux/actions';
import UserService from '../../../services/user';
import AuthService from '../../../services/auth';
import GeneralService from '../../../services/general';
import PlantService from '../../../services/plant';
import { CODES } from '../../../utils/codesHTTP';
import { Global } from '../../../config/general';
import { colors } from '../../../assets/colors';
import { clearStorage } from '../../../utils/function';
import Swal from 'sweetalert2';
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../message/validations';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { titlesAlerts } from '../../../message/titlesAlerts';

const NavBar = ({ userInfo }) => {
    const pathInit = Global.path;
    const valuePlantStorage = Global.valuePlantStorage;

    const navigate = useNavigate();
    const idUsuario = userInfo?.user?.id_usuario;
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDark, setIsDark] = useState(true);

    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));
    const selectedInfo = useSelector(state => state);
    const plants = useSelector(state => state.plants);
    const allPlants = useSelector(state => state.allPlants);
    const [menu, setMenu] = useState(null);
    const [categories, setCategories] = useState(null);
    const [screens, setScreens] = useState(null);

    // Almacena el valor seleccionado en el almacenamiento local al cambiar
    const handleValuePlant = async (value) => {
        if (value === 0) return; 
        const plantSelect = allPlants?.find(p => +p.id_planta === +value);    
        if (!plantSelect?.isFlag) {
            const saveSuccessful = await saveUserPlants(plantSelect);
            if (saveSuccessful) {
                localStorage.setItem(valuePlantStorage, value.toString());
                dispatch(setSelectedInfo({ valuePlant: value }));
            }
        } else {
            localStorage.setItem(valuePlantStorage, value.toString());
            dispatch(setSelectedInfo({ valuePlant: value }));
        }
    };
    
    const saveUserPlants = async (plant) => {
        const body = {
            id_usuario: idUsuario,
            id_estado: 1,
            plantas: [plant],
        };
    
        const resultSwal = await Swal.fire({
            title: titlesAlerts.confirm_user_plants,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });
    
        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(titlesSpinner.userPlants_add);
                const result = await UserService.saveUserPlants(body);
                
                if (result.status === CODES.SUCCESS_200) {
                    simpleAlerts({ message: result?.data?.message, type: "success" });
                    await showGroupingUser(allPlants);
                    return true;
                } else {
                    simpleAlerts({ message: result?.response?.data?.message || "Error", type: "error" });
                    return false;
                }
            } catch (error) {
                console.log(`Error en saveUserPlants: ${error}`);
                return false;
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    
        return false; // Retornar fallo si el usuario cancela
    };

    // Recupera el valor almacenado al inicializar el componente
    useEffect(() => {
        const storedValue = localStorage.getItem(valuePlantStorage);
        if (storedValue && allPlants) {
            handleValuePlant(parseInt(storedValue));
        }
    }, [allPlants]);

    /*
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
            setIsDark(window.scrollY <= 10);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    */
    useEffect(() => {
        showPlants();
        showMenu();
    }, [idUsuario]);

    const showPlants = async () => {
        try {
            const params = { id_estado: 1 }
            const result = await PlantService.showAll(params)
            if (result.status === CODES.SUCCESS_200) {
                showGroupingUser(result?.data);
            }
            return []
        } catch (error) {
            console.log(`Error en showPlants: ${error}`);
        }
    }

    const showGroupingUser = async (allPlantsInit) => {
        const params = {
            id_usuario: idUsuario,
        };
        try {
            dispatch(setSelectedInfo({ plantGroups: null }));
            const result = await UserService.showGroupingUser(params)
            if (result.status === CODES.SUCCESS_200) {
                calculateAssignedPlants(allPlantsInit, result?.data?.plantas);
                dispatch(setSelectedInfo({
                    plants: result?.data?.plantas,
                    areas: result?.data?.areas,
                    subAreas: result?.data?.subAreas,
                    places: result?.data?.lugares,
                    headquarters: result?.data?.sedes,
                }));
            }
            return []
        } catch (error) {
            console.log(`Error en showGroupingUser: ${error}`);
        }
    }

    const calculateAssignedPlants = (allPlantsInit, assignedPlants) => {
        const plantsIds = new Set(assignedPlants.map(plant => Number(plant.id_planta)));
        const resultPlants = allPlantsInit.map(plant => ({
            ...plant,
            isFlag: plantsIds.has(plant.id_planta) // Marca si el id_planta está en ambos arrays
        })).sort((a, b) => b.isFlag - a.isFlag);
        dispatch(setSelectedInfo({ allPlants: resultPlants }));
    }

    const showMenu = async () => {
        const params = {
            id_usuario: idUsuario,
        };
        try {
            setMenu(null);
            const result = await GeneralService.showMenu(params);
            if (result.status === CODES.SUCCESS_200) {
                setMenu(result.data);
            } else if (result.response.status === CODES.VALIDATION_ERROR) {
                simpleAlerts({ message: result.response?.data.message || validationMessages.validation_error, type: "error" })
                setTimeout(() => {
                    navigate((pathInit || '/'));
                    window.location.reload();
                }, 3000);
            } else {
                simpleAlerts({ message: result.response?.data.error || validationMessages.expired_session, type: "error" })
                setTimeout(() => {
                    closing_session();
                }, 3000);
            }
            return []
        } catch (error) {
            console.log(`Error en setMenu: ${error}`);
        }
    }

    useEffect(() => {
        if (menu) {
            const categoriesTmp = menu.reduce((acc, item) => {
                if (item.id_categoriaPantalla && item.desCategoriaPantalla) {
                    // Buscamos si el item ya existe en el acumulador basado en `id_categoriaPantalla`
                    const exists = acc.find(cat => cat.id_categoriaPantalla === item.id_categoriaPantalla);
                    if (!exists) {
                        // Si no existe, lo añadimos al acumulador
                        acc.push({
                            id_categoriaPantalla: item.id_categoriaPantalla,
                            desCategoriaPantalla: item.desCategoriaPantalla
                        });
                    }
                }
                return acc;
            }, []);
            setCategories(categoriesTmp);
        }
        setScreens(menu?.filter(e => !e.id_categoriaPantalla));
    }, [menu])

    const handleItemClick = (route) => {
        navigate(route);
    };

    const signOut = async () => {
        Swal.fire({
            title: '¿Seguro de Cerrar Sesión?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colors.secondary.hex,
            cancelButtonColor: colors.primary.hex,
            confirmButtonText: 'Si, Cerrar Sesión'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'error',
                    title: 'Cerrando...',
                    timer: 3000
                })
                setTimeout(
                    AuthService.signOut().then(result => {
                        if (result.status === CODES.BAD_TOKEN_498 || result.status === CODES.SUCCESS_200) {
                            closing_session();
                        }
                    }), 5000);
            }
        })
    }

    const closing_session = () => {
        clearStorage();
        setTimeout(() => {
            navigate(pathInit);
            window.location.reload();
        }, 1000);
    }

    return (
        <nav className={`navbar navbar-expand-lg navbar-light bg-navbar-${isDark ? 'dark' : 'light'} fixed-top ${isScrolled ? 'scroll' : ''}`}>
            <div className="container-fluid">
                <a className={`navbar-brand ${isDark ? 'text-white' : 'text-dark'}`} href={`${(pathInit || '/')}`}>RUNA-SST</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className={`navbar-nav me-auto fs-small`}>
                        {categories?.map((category) => {
                            const menuList = menu?.filter(e => +e.id_categoriaPantalla === +category.id_categoriaPantalla);
                            return (
                                <li key={`dropdown-${category.id_categoriaPantalla}`} className="nav-item dropdown">
                                    <a className={`nav-link dropdown-toggle ${isDark ? 'text-white' : 'text-dark'}`} href="" id={`navbarDropdown-${category.id_categoriaPantalla}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {category.desCategoriaPantalla}
                                    </a>
                                    <ul className={`dropdown-menu bg-navbar-dropdown-${isDark ? 'dark' : 'light'} fs-small`} aria-labelledby={`navbarDropdown-${category.id_categoriaPantalla}`}>
                                        {menuList?.map((item) => (
                                            <li key={`menu-${item.id_pantalla}`} onClick={() => handleItemClick(`${item.link}`)}>
                                                <a className={`dropdown-item option-li-${isDark ? 'dark' : 'light'}`} href="">{item.label}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            );
                        })}

                        {screens?.map((item, idx) => {
                            return (
                                <li key={`menu-${item.id_pantalla}`} className="nav-item" onClick={() => handleItemClick(`${item.link}`)}>
                                    <a className={`nav-link ${isDark ? 'text-white' : 'text-dark'}`}>{item.label}</a>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="d-flex">
                        <select
                            className={`form-select me-2 text-dark`}
                            aria-label="Default select example"
                            value={selectedInfo.valuePlant || 0}
                            onChange={(e) => handleValuePlant(+e.target.value)}
                        >
                            <option value={0}>Seleccionar opción</option>
                            {allPlants?.map((opt, idxOpt) => {
                                return (
                                    <option
                                        key={`grouping-opt-${idxOpt}`}
                                        style={{ backgroundColor: opt.isFlag ? '#abebc6' : '#f5b7b1', }}
                                        value={opt?.value}
                                    >{opt?.label}</option>
                                );
                            })}
                        </select>
                        <button
                            className={`btn btn-${isDark ? 'danger' : 'dark'}`}
                            type="button"
                            onClick={signOut}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            log out
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;