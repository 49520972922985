import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = 'worker';
const url = window.location.pathname;
const commonParams = { url: url };

class WorkerService {
    async showByPlant(queryParameters) {
        const params = { ...commonParams, pageSize: 20, pagination: 1, ...queryParameters };
        const ruta = `${API_URL}/${prefix}/plant`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }
    
    async showOthers(queryParameters) {
        const params = { ...commonParams, ...queryParameters };
        const ruta = `${API_URL}/${prefix}/others`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }


    async showEvaluador(queryParameters) {
        const params = { ...commonParams, pageSize: 20, pagination: 1, ...queryParameters };
        const ruta = `${API_URL}/${prefix}/evaluador`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

     async registerOrCheckPerson(body) { // registrar tercero cuando no exista en la tabla y no pertenezca a lavoro 
            const ruta = `${API_URL}/${prefix}/tercero`;
            const headers = await headerRequest();
            const params = { ...commonParams };
            return axios.post(ruta, body, {
                headers: headers,
                params: params,
            }).catch((error) => {
                return error;
            });
        }
    

        async showOthersByName(queryParameters) {
            const params = { ...commonParams, ...queryParameters };
            const ruta = `${API_URL}/${prefix}/othersByName`;
            const headers = await headerRequest();
            return axios.get(ruta, {
                params: params,
                headers: headers,
            }).catch((error) => {
                return error;
            });
        }
}

export default new WorkerService();