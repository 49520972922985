import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './App.css';
import './assets/styles/main.scss';
import { ToastContainer, } from "react-toastify";
/* services */
import AuthService from './services/auth';
/* pages */
import UnsafeCondition from './app/pages/UnsafeCondition/UnsafeCondition';
import UnsafeConditionClosure from './app/pages/UnsafeCondition/UnsafeConditionClosure';
import UnsafeBehavior from './app/pages/UnsafeBehavior/UnsafeBehavior';
import Definitions from './app/pages/Definitions/Structure/Definitions';
import DefinitionsConcepts from './app/pages/Definitions/Concepts/DefinitionsConcepts';
import UnsafeConditionConcepts from './app/pages/Definitions/Concepts/UnsafeConditionConcepts';
import DefinitionsTypeEvent from './app/pages/Definitions/Concepts/TypeEvent/DefinitionsTypeEvent';
import Inspection from './app/pages/Inspection/Inspection';
import Login from './app/pages/Login/Login';
import Report from './app/pages/Report/Report';
/* components */
import NavBar from './app/components/Frame/NavBar';
import SpinnerLoader from './app/components/General/SpinnerLoad/SpinnerLoader';
/* conifg */
import { Global } from './config/general';
/* utils */
import { CODES } from './utils/codesHTTP';
import { simpleAlerts } from './utils/alerts';
import { clearStorage } from './utils/function';
import Accident from './app/pages/Accident/Accident';
import User from './app/pages/User/User';
import Home from './app/pages/Home/Home';
import { titlesAlerts } from './message/titlesAlerts';
import ViewInspection from './app/pages/Inspection/ViewInspection';
import SeeDetailInspection from './app/pages/Inspection/SeeDetailInspection/SeeDetailInspection';

function App() {
  const pathInit = Global.path;
  const info = Global.info;
  const infoTime = Global.infoTime;
  const [userInfo, setUserInfo] = useState(null);
  const openLoader = useSelector(state => state.openLoader);
  const titleLoader = useSelector(state => state.titleLoader);
  const location = useLocation();
  // useEffect(() => {
  //   const storedToken = JSON.parse(localStorage.getItem(Global.info));
  //   const tokenExpiration = localStorage.getItem(Global.infoTime);

  //   if (storedToken && tokenExpiration && new Date() < new Date(tokenExpiration)) {
  //     setUserInfo(storedToken);
  //   }
  //   me()
  // }, []);

  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem(info));
    const tokenExpiration = localStorage.getItem(infoTime);

    if (storedToken) {
      if (storedToken && tokenExpiration && new Date() < new Date(tokenExpiration)) {
        setUserInfo(storedToken);
      } else {
        console.log(":", tokenExpiration)
        me()
      }
    }
  }, []);

  const me = async () => {
    try {
      const result = await AuthService.me();
      if (result?.response?.status === CODES.BAD_TOKEN_498) {
        simpleAlerts({ message: titlesAlerts.log_out }, 'error');
        setTimeout(() => {
          setUserInfo(null)
          clearStorage();
          window.location.reload();
        }, 1500);
      }
      return []
    } catch (error) {
      console.log(`Error en showCompanies: ${error}`);
    }
  }

  const homeMenu = window.location.pathname?.toLowerCase() === `${(pathInit?.toLowerCase() || '/')}` || (pathInit?.toLowerCase() && (window.location.pathname?.toLowerCase() === `${(pathInit?.toLowerCase() + '/')}`));

  return (
    <div>
      <ToastContainer limit={3} closeOnClick={true} />
      <SpinnerLoader
        open={openLoader}
        title={titleLoader}
      />
      {userInfo ?
        (<div
          className={`App`}
          style={{
            paddingTop: (homeMenu ? "3rem" : "4.5rem"),
            paddingLeft: "1rem",
            paddingRight: "1rem",
            minHeight: location.pathname.includes("/definitions") ? "120vh" : "100vh",
          }} >
          {!homeMenu &&
            <NavBar userInfo={userInfo} />
          }
          <div className='body-web'>
            <BodyWeb
              pathInit={pathInit}
              userInfo={userInfo}
            />
          </div>
        </div>)
        : <Login setUserInfo={setUserInfo} />
      }
    </div>
  );
}

function BodyWeb({ pathInit, userInfo, }) {
  return (
    <div>
      <Routes>
        <Route path={`${(pathInit || '/')}`} element={<Home userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/definitions/structure`} element={<Definitions userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/definitions/concepts`} element={<DefinitionsConcepts userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/definitions/unsafe-condition`} element={<UnsafeConditionConcepts userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/definitions/type-event`} element={<DefinitionsTypeEvent userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/unsafe-condition`} element={<UnsafeCondition userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/unsafe-condition-closure`} element={<UnsafeConditionClosure userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/unsafe-behavior`} element={<UnsafeBehavior userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/accident-incident`} element={<Accident userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/inspection`} element={<ViewInspection userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/inspection/:codigo`} element={<Inspection userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/inspection-detail/:idType/:idInspection`} element={<SeeDetailInspection />} />
        <Route path={`${(pathInit || '')}/user`} element={<User userInfo={userInfo} />} />
        <Route path={`${(pathInit || '')}/report`} element={<Report userInfo={userInfo} />} />
      </Routes>
    </div>
  );
}
export default App;