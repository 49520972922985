import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GeneralService from '../../../services/general';
import TypeConditionService from '../../../services/typeCondition';
import UnsafeConditionService from '../../../services/unsafeCondition';
import CustomSelect from "../../components/General/CustomSelect";
import IncidentLocationFields from '../../components/General/IncidentLocationFields';
import CustomTextArea from '../../components/General/CustomTextArea';
import CustomCheck from '../../components/General/CustomCheck';
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../message/validations';
import { getDateOL } from '../../../utils/function';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { titlesAlerts } from '../../../message/titlesAlerts';
import { errorsMessages } from '../../../message/errors';
import { CODES } from '../../../utils/codesHTTP';
import DynamicTitle from '../../../utils/DynamicTitle';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { Global } from '../../../config/general';

const newInfo = {
    fecha: getDateOL("", "large"),
    planta_id: null,
    area_id: null,
    subArea_id: null,
    lugar_id: null,
    subAreaLugar_id: null,
    turno_id: null,
    categoTipoCondic_id: null,
    tipoTrabajador_id: null,
    desSuceso: null,
    desAccion: null,
    desCierre: null,
    imagenes: [],
    imagenesCierre: [],
}

const UnsafeCondition = () => {
    const path = Global.path;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const places = useSelector(state => state.places);
    const valuePlant = useSelector(state => state.valuePlant);

    const [info, setInfo] = useState(newInfo);
    const [valueTypeCondition, setValueTypeCondition] = useState(null);
    const [dataTypeWorker, setDataTypeWorker] = useState(null);
    const [dataTypeCondition, setDataTypeCondition] = useState(null);
    const [dataShift, setDataShift] = useState(null);
    const [dataCategory, setDataCategory] = useState(null);
    const [checkClosure, setCheckClosure] = useState(false);

    const [turno, setTurno] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        handleOpenLoader(true);
        Promise.all([
            showTypeWorker(),
            showTypeCondition(), 
            showTurnos()
        ]).then(() => handleOpenLoader(false));
    }

    const showTypeWorker = async () => {
        try {
            setDataTypeWorker(null);
            const result = await GeneralService.showTypeWorker();
            setDataTypeWorker(result.data);
            return [];
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    const showTypeCondition = async () => {
        try {
            setDataTypeCondition(null);
            const result = await TypeConditionService.showAll();
            const filteredData = result.data.filter(item => +item.estado_id !== 2);
            setDataTypeCondition(filteredData);
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    };
    

    useEffect(() => {
        if (valuePlant > 0) {
            showShiftByPlant();
        }
    }, [valuePlant]);

    const showShiftByPlant = async () => {
        try {
            setDataShift(null);
            const result = await GeneralService.showShiftByPlant(valuePlant);
            setDataShift(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        }
    }

    useEffect(() => {
        setDataCategory(null);
        if (valueTypeCondition > 0)
            showCategoryByTypeCondition();
    }, [valueTypeCondition]);

    const showCategoryByTypeCondition = async () => {
        try {
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.categoyCondition_list);
            setDataCategory(null);
            const result = await TypeConditionService.showCategoryByTypeCondition(valueTypeCondition);
            setDataCategory(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showCategoryByTypeCondition: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const allowedFileTypes = ['.jpg', '.jpeg', '.png'];
    const handleFilesChange = (e, field) => {
        const files = e.target.files;

        if (files.length > 5) {
            simpleAlerts({ message: errorsMessages.max_files_images });
            return; // Detiene la función si hay más de 5 archivos
        }

        let updData = _.cloneDeep(info);
        if (updData[field]) {
            updData[field] = [];
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileExtension = file?.name?.split('.').pop().toLowerCase();
            if (!allowedFileTypes.includes(`.${fileExtension}`)) {
                simpleAlerts({ message: errorsMessages.type_image })
            } else {
                let small;
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        small = { file: file, img: event.target.result, name: file?.name };
                        updData[field].push({ file: file, small: small });
                        setInfo(updData);
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    const handleRemoveFile = (field, idx) => {
        let updData = _.cloneDeep(info);
        updData[field] = updData[field]?.filter((e, i) => +i !== idx);
        return setInfo(updData);
    };

    const viewFile = (info) => {
        const fileURL = URL.createObjectURL(info?.small?.file);
        if (info?.small?.file) {
            window.open(fileURL, '_blank');
        }
    };

    const resetInfo = () => {
        setInfo(newInfo);
        setValueTypeCondition(null);
    }

    const saveUnsafeCondition = async (e) => {
        e.preventDefault();
        // let data = new FormData(e.target);
        // data.forEach((value, key) => {
        //     console.log(key, value);
        // });

        let updData = _.cloneDeep(info);
        if (!updData.fecha || updData.fecha === "") {
            simpleAlerts({ message: validationMessages.date })
            return;
        }
        if (!updData.lugar_id || updData.lugar_id === 0) {
            simpleAlerts({ message: validationMessages.lugar })
            return;
        }
        if (!updData.turno_id || updData.turno_id === 0) {
            simpleAlerts({ message: validationMessages.turno })
            return;
        }
        if (!updData.tipoTrabajador_id || updData.tipoTrabajador_id === 0) {
            simpleAlerts({ message: validationMessages.tipoTrabajador })
            return;
        }
        if (!updData.categoTipoCondic_id || updData.categoTipoCondic_id === 0) {
            simpleAlerts({ message: validationMessages.categoria })
            return;
        }
        if (!updData.desSuceso || updData.desSuceso === "") {
            simpleAlerts({ message: validationMessages.desSuceso })
            return;
        }
        if (!updData.desAccion || updData.desAccion === "") {
            simpleAlerts({ message: validationMessages.desAccion })
            return;
        }

        if (updData.imagenes.length === 0) {
            simpleAlerts({ message: "Debe subir al menos una imagen." });
            return;
        }

        if (checkClosure){
            if (updData.imagenesCierre.length === 0) {
                simpleAlerts({ message: "Debe subir al menos una imagen de cierre." });
                return;
            }
            if (!updData.desCierre || updData.desCierre === "") {
                simpleAlerts({ message: validationMessages.desCierre })
                return;
            }
        }

        updData.planta_id = valuePlant;
        updData.subAreaLugar_id = places?.find(e =>
        (+e.planta_id === +updData.planta_id &&
            +e.area_id === +updData.area_id &&
            +e.subArea_id === +updData.subArea_id &&
            +e.id_lugar === +updData.lugar_id)
        )?.id_subAreaLugar

        const resultSwal = await Swal.fire({
            title: titlesAlerts.confirm_unsafe_condition,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(titlesSpinner.condicionInsegura_save);
                const result = await UnsafeConditionService.saveUnsafeCondition(updData);
                if (result.status === CODES.CREATE_201) {
                    resetInfo();
                    simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
                } else {
                    console.log(result?.response);
                    simpleAlerts({ message: errorsMessages.unsafe_behavior, type: "error" })
                }
                return []
            } catch (error) {
                console.log(`Error en saveUnsafeCondition: ${error}`);
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    const showTurnos = async () => {
        try {
            setDataTypeWorker(null);
            const result = await GeneralService.showTurnos();
            setTurno(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showTurnos: ${error}`);
        }
    }

    const handleChangeCheck = (value, info) => {
        setCheckClosure(value);
    }

    return (
        <form className="px-3"
            onSubmit={saveUnsafeCondition}
        >
            <DynamicTitle title="Condición insegura" />
            <h1 className='text-center title-page'>Condición Insegura</h1>
            <div className='row justify-content-center'>
                <div className='col-8'>
                    <a href={`${path || ''}/unsafe-condition-closure`}
                        style={{
                            fontSize: "13px",
                            textDecoration: "none",
                            marginTop: "5px",
                            marginBottom: "8px",
                        }}
                    >Cierre de casos pendientes</a>
                </div>
            </div>
            <section className='form-register'>
                <IncidentLocationFields
                    info={info}
                    setInfo={setInfo}
                />
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomSelect
                            title={'Turno'}
                            placeholder={'Selecionar Turno'}
                            options={turno}
                            value={info.turno_id || 0}
                            onChange={(value, event) => setInfo({ ...info, turno_id: value })}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <CustomSelect
                            title={'Condición a cargo de:'}
                            placeholder={'Selecionar Tipo'}
                            options={dataTypeWorker}
                            value={info.tipoTrabajador_id || 0}
                            onChange={(value, event) => setInfo({ ...info, tipoTrabajador_id: value })}
                        />
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomSelect
                            title={'Tipo de Condición'}
                            placeholder={'Selecionar Tipo'}
                            options={dataTypeCondition}
                            value={valueTypeCondition || 0}
                            onChange={(value, event) => setValueTypeCondition(value)}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <CustomSelect
                            title={'Categoria de Condición'}
                            placeholder={'Selecionar Categoria'}
                            options={dataCategory}
                            value={info.categoTipoCondic_id || 0}
                            onChange={(value, event) => setInfo({ ...info, categoTipoCondic_id: value })}
                        />
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomTextArea
                            title="Descripción:"
                            placeholder="ingrese una descripción 500 max."
                            propsInput={{ maxLength: 500, }}
                            name="desSuceso"
                            value={info?.desSuceso || ""}
                            onChange={(e) => setInfo({ ...info, desSuceso: e.target.value })}
                            required={true}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <CustomTextArea
                            title="Acciones:"
                            placeholder="ingrese una descripción 500 max."
                            propsInput={{ maxLength: 500, }}
                            name="desAccion"
                            value={info?.desAccion || ""}
                            onChange={(e) => setInfo({ ...info, desAccion: e.target.value })}
                            required={true}
                        />
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <div className='text-center'>
                            <input
                                id={`fileInput-form-register`}
                                type="file"
                                accept={allowedFileTypes}
                                onChange={(e) => handleFilesChange(e, 'imagenes')}
                                style={{ display: 'none' }}
                                multiple
                            />
                            <button
                                className='btn btn-sm btn-outline-primary ms-2'
                                type='button'
                                onClick={() => document.getElementById(`fileInput-form-register`).click()}
                                title='Subir imagen'
                            >
                                <CloudUploadIcon color="inherit" fontSize='small' />
                            </button>
                        </div>
                        <CustomCheck
                            id={"unsafe-condition"}
                            label={"Cierre de condición"}
                            checked={checkClosure}
                            handleChangeCheck={handleChangeCheck}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        {info?.imagenes?.length > 0 &&
                            <div className='table-container-wrapper mt-2'>
                                <div className='table-container'>
                                    {info?.imagenes?.map((row, idxRow) => {
                                        return (
                                            <div key={`row-image-${idxRow}`} className='row-worker-container'>
                                                <div className='col-worker-container'>
                                                    <button
                                                        className='btn btn-sm btn-outline-danger ms-2 p-0 border-0'
                                                        type='button'
                                                        onClick={() => handleRemoveFile("imagenes", idxRow)}
                                                        title='Quitar imagen'
                                                    >
                                                        <CancelIcon fontSize='small' />
                                                    </button>
                                                </div>
                                                <div className='col-worker-container' style={{ width: "93%" }}>
                                                    <img
                                                        src={row.small.img}
                                                        alt="Debe seleccionar una imagen correcta"
                                                        style={{ width: '30px', cursor: 'pointer' }}
                                                        className='ms-3'
                                                        onClick={() => viewFile(row)}
                                                    />
                                                    <label className='ms-2'>{row.small.name}</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={`row justify-content-center gap-2 fade-transition ${checkClosure ? 'fade-visible' : ''}`}>
                    <div className='col-md-4 col-sm-8'>
                        <div className='text-center'>
                            <input
                                id={`fileInput-form-register-cierre`}
                                type="file"
                                accept={allowedFileTypes}
                                onChange={(e) => handleFilesChange(e, 'imagenesCierre')}
                                style={{ display: 'none' }}
                                multiple
                            />
                            <button
                                className='btn btn-sm btn-outline-primary ms-2'
                                type='button'
                                onClick={() => document.getElementById(`fileInput-form-register-cierre`).click()}
                                title='Subir imagen'
                            >
                                <CloudUploadIcon color="inherit" fontSize='small' />
                            </button>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        {info?.imagenesCierre?.length > 0 &&
                            <div className='table-container-wrapper mt-2'>
                                <div className='table-container'>
                                    {info?.imagenesCierre?.map((row, idxRow) => {
                                        return (
                                            <div key={`row-imageCierre-${idxRow}`} className='row-worker-container'>
                                                <div className='col-worker-container'>
                                                    <button
                                                        className='btn btn-sm btn-outline-danger ms-2 p-0 border-0'
                                                        type='button'
                                                        onClick={() => handleRemoveFile("imagenesCierre", idxRow)}
                                                        title='Quitar imagen'
                                                    >
                                                        <CancelIcon fontSize='small' />
                                                    </button>
                                                </div>
                                                <div className='col-worker-container' style={{ width: "80%" }}>
                                                    <img
                                                        src={row.small.img}
                                                        alt="Debe seleccionar una imagen correcta"
                                                        style={{ width: '30px', cursor: 'pointer' }}
                                                        className='ms-3'
                                                        onClick={() => viewFile(row)}
                                                    />
                                                    <label className='ms-2'>{row.small.name}</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='col-8'>
                        <CustomTextArea
                            title="Descipción cierre"
                            placeholder="ingrese una descripción 500 max."
                            propsInput={{ maxLength: 500, }}
                            value={info?.desCierre || ""}
                            onChange={(e) => setInfo({ ...info, desCierre: e.target.value })}
                        />
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-8 d-flex justify-content-evenly'>
                        <button
                            className='btn btn-danger w-25'
                            // onClick={addPlace}
                            title='Cancelar'
                        >
                            Cancelar
                        </button>
                        <button
                            className='btn btn-block btn-primary w-25'
                            type='submit'
                            title='Guardar'
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default UnsafeCondition